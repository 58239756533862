<template>
  <v-list
    class="pt-0"
    dense
  >
    <v-subheader
      class="primary subtitle-2 u-text-overflow"
      dark
    >
      {{ item.vendor_name }}
    </v-subheader>

    <v-list-item
      @click="editVendor"
      class="mt-2"
      link
    >
      <v-list-item-icon>
        <v-icon color="secondary">fas fa-pen</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title
          class="secondary--text"
          v-text="'Editar'"
        />
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      @click="removeVendor"
      class="mt-2"
      link
    >
      <v-list-item-icon>
        <v-icon color="error">fas fa-trash</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title
          class="error--text"
          v-text="'Excluir'"
        />
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    removeVendor() {},
  },
}
</script>
